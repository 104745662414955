import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledYouTube = styled.div`

    overflow: hidden;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    margin-top: 55px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
`
const YouTube = ({ id }) => {

    return (
        <StyledYouTube>
            <iframe 
                title={id}
                src={`https://www.youtube-nocookie.com/embed/${id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </StyledYouTube>
    )

}

YouTube.prototype = {
    id: PropTypes.string.isRequired,
}

export default YouTube